import React from "react";
import "./project.css";
import { useState } from 'react';
import bobachee from "../Images/bobachee-img.jpg";

const Project = () => {

  const [selectedImage, setSelectedImage] = useState(null);

  const images = [
     bobachee,
    'https://b.zmtcdn.com/data/pictures/5/18370355/0ad52cd179fdc4cd4d0330d54865d879.jpg',
    'https://www.tata.com/content/dam/tata/images/heritage/desktop/54-Dekstop.jpg',
    'https://www.zricks.com/ImagesPostProject/200000000000217034/3.jpg',
    'https://jll-global-gdim-res.cloudinary.com/image/upload/t_prod-ap-property-detail-web/v1505552951/IN_ML20170916/Splendor-Forum_4135_20170916_001.jpg',
    'https://th.bing.com/th/id/OIP.KjZXlD1JDsrKfJOtmFJV5AHaFj?rs=1&pid=ImgDetMain',
    'https://sg1-cdn.pgimgs.com/projectnet-project/17887/ZPPHO.96973910.R800X800.jpg',
    'https://pix10.agoda.net/hotelImages/175/175181/175181_14060918200019758588.jpg?s=1024x768',
    'https://pix10.agoda.net/hotelImages/4592284/-1/335c860ca8309428e83853c37dd6ab5a.jpg?s=1024x768',
    'https://vivekvarmaarchitects.com/wp-content/uploads/2018/06/1-12.jpg',
    'https://th.bing.com/th/id/OIP.fLTqsxWibSDRYiW8K84-NQAAAA?rs=1&pid=ImgDetMain',
    'https://www.theindianwire.com/wp-content/uploads/2021/03/Maruti-Suzuki.jpg',
    'https://b.zmtcdn.com/data/pictures/8/21109568/89734a82e68f9fcffe2acdb9653c1cfa.jpg',
    'https://i.pinimg.com/originals/6c/d9/0a/6cd90a15df19b5d3dd00013b34e94814.jpg',
    'https://th.bing.com/th/id/OIP.Mq_2xm-8oQxHsujjLs0VtQHaE8?rs=1&pid=ImgDetMain',
    'https://th.bing.com/th/id/OIP.XTS_H-eQLIOTQa96ze8tjQHaE8?rs=1&pid=ImgDetMain',
    'https://cdn1.goibibo.com/voy_mmt/t_g/htl-imgs/202102011415025409-6a0d7ea4624311eb94b90242ac110002.jpg',
    'https://i.pinimg.com/originals/35/0b/54/350b54ca469816df5e127ad9d42095f0.jpg',
    'https://th.bing.com/th/id/OIP.jWggH3tyQtck5N7RIrk66AAAAA?rs=1&pid=ImgDetMain',
    'https://cdn.dnaindia.com/sites/default/files/styles/full/public/2018/02/21/650686-517884-463269-toll-plaza-representatinal-2.jpg',
    'https://content.jdmagicbox.com/comp/delhi/29/011pf021829/catalogue/nath-brothers-connaught-place-delhi-chemists-22daauf.jpg',
  ];

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  const closeModal = () => {
    setSelectedImage(null);
  };


  return (
<div className="inner-project-container">
    <h2 className="project-h2">Recent Works</h2>
    <hr className="project-hr" />

    <div className="gallery-container">
      <div className="image-grid">
        {images.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`Thumbnail ${index + 1}`}
            className="thumbnail"
            onClick={() => handleImageClick(image)}
          />
        ))}
      </div>

      {selectedImage && (
        <div className="modal" onClick={closeModal}>
          <img src={selectedImage} alt="Zoomed" className="modal-image" />
        </div>
      )}
    </div>
    </div>

    // <div>
    //   {/* <div className="project-hero-div">
    //     <img className="project-hero-img" src="https://mir-s3-cdn-cf.behance.net/project_modules/1400/26698f29504947.55f6cb41643b4.jpg" /> 
    //   </div> */}
    //   <div className="main-projects">
    //     <h2 className="project-h2">Recent Works</h2>
    //     <hr className="project-hr" />
    //     <div className="container inner-project-container">
    //       <div className="row">
    //         <div className="col inner-project-div">
    //           <img
    //             className="inner-project-img"
    //             src="https://b.zmtcdn.com/data/pictures/5/18370355/0ad52cd179fdc4cd4d0330d54865d879.jpg"
    //           />
    //           <h2 className="project-name">Moti Mahal Delux</h2>
    //         </div>
    //         <div className="col project-div">
    //           <img
    //             className="inner-project-img"
    //             src="https://www.tata.com/content/dam/tata/images/heritage/desktop/54-Dekstop.jpg"
    //           />
    //           <h2 className="project-name">Tata Croma</h2>
    //         </div>
    //         <div className="col project-div">
    //           <img
    //             className="inner-project-img"
    //             src="https://www.zricks.com/ImagesPostProject/200000000000217034/3.jpg"
    //           />
    //           <h2 className="project-name">Express trade Tower-2</h2>
    //         </div>
    //         <div className="col project-div">
    //           <img
    //             className="inner-project-img"
    //             src="https://jll-global-gdim-res.cloudinary.com/image/upload/t_prod-ap-property-detail-web/v1505552951/IN_ML20170916/Splendor-Forum_4135_20170916_001.jpg"
    //           />
    //           <h2 className="project-name">Splendor Forum</h2>
    //         </div>
    //         <div className="col project-div">
    //           <img
    //             className="inner-project-img"
    //             src="https://th.bing.com/th/id/OIP.KjZXlD1JDsrKfJOtmFJV5AHaFj?rs=1&pid=ImgDetMain"
    //           />
    //           <h2 className="project-name">BAANI Corporate One</h2>
    //         </div>
    //         <div className="col project-div">
    //           <img
    //             className="inner-project-img"
    //             src="https://sg1-cdn.pgimgs.com/projectnet-project/17887/ZPPHO.96973910.R800X800.jpg"
    //           />
    //           <h2 className="project-name">Aricia International</h2>
    //         </div>
    //         <div className="col project-div">
    //           <img
    //             className="inner-project-img"
    //             src="https://pix10.agoda.net/hotelImages/175/175181/175181_14060918200019758588.jpg?s=1024x768"
    //           />
    //           <h2 className="project-name">Iris Park Hotel (Safdarjung Enclave)</h2>
    //         </div>
    //         <div className="col project-div">
    //           <img
    //             className="inner-project-img"
    //             src="https://pix10.agoda.net/hotelImages/4592284/-1/335c860ca8309428e83853c37dd6ab5a.jpg?s=1024x768"
    //           />
    //           <h2 className="project-name">Lemon Tree Hotel (Bhatinda, Punjab)
    //           </h2>
    //         </div>
    //         <div className="col project-div">
    //           <img
    //             className="inner-project-img"
    //             src="https://vivekvarmaarchitects.com/wp-content/uploads/2018/06/1-12.jpg"
    //           />
    //           <h2 className="project-name">Peninsula Mall (Bhatinda)</h2>
    //         </div>
    //         <div className="col project-div">
    //           <img
    //             className="inner-project-img"
    //             src="https://th.bing.com/th/id/OIP.fLTqsxWibSDRYiW8K84-NQAAAA?rs=1&pid=ImgDetMain"
    //           />
    //           <h2 className="project-name">Hero Honda Showroom (Solan, Himachal Pradesh)
    //           </h2>
    //         </div>
    //         <div className="col project-div">
    //           <img
    //             className="inner-project-img"
    //             src="https://www.theindianwire.com/wp-content/uploads/2021/03/Maruti-Suzuki.jpg"
    //           />
    //           <h2 className="project-name">Vauz Maruti Showroom (Patna, Bihar)
    //           </h2>
    //         </div>
    //         <div className="col project-div">
    //           <img
    //             className="inner-project-img"
    //             src="https://b.zmtcdn.com/data/pictures/8/21109568/89734a82e68f9fcffe2acdb9653c1cfa.jpg"
    //           />
    //           <h2 className="project-name">Bobachee (South Extension Part 2)
    //           </h2>
    //         </div>
    //         <div className="col project-div">
    //           <img
    //             className="inner-project-img"
    //             src="https://i.pinimg.com/originals/6c/d9/0a/6cd90a15df19b5d3dd00013b34e94814.jpg"
    //           />
    //           <h2 className="project-name">Suchir Lighting (Noida)
    //           </h2>
    //         </div>
    //         <div className="col project-div">
    //           <img
    //             className="inner-project-img"
    //             src="https://th.bing.com/th/id/OIP.Mq_2xm-8oQxHsujjLs0VtQHaE8?rs=1&pid=ImgDetMain"
    //           />
    //           <h2 className="project-name">Printtech (Noida)
    //           </h2>
    //         </div>
    //         <div className="col project-div">
    //           <img
    //             className="inner-project-img"
    //             src="https://th.bing.com/th/id/OIP.XTS_H-eQLIOTQa96ze8tjQHaE8?rs=1&pid=ImgDetMain"
    //           />
    //           <h2 className="project-name">South Delhi Public School (Defence Colony)
    //           </h2>
    //         </div>
    //         <div className="col project-div">
    //           <img
    //             className="inner-project-img"
    //             src="https://cdn1.goibibo.com/voy_mmt/t_g/htl-imgs/202102011415025409-6a0d7ea4624311eb94b90242ac110002.jpg"
    //           />
    //           <h2 className="project-name">Lemon Tree Hotel (Aligarh)
    //           </h2>
    //         </div>
    //         <div className="col project-div">
    //           <img
    //             className="inner-project-img"
    //             src="https://i.pinimg.com/originals/35/0b/54/350b54ca469816df5e127ad9d42095f0.jpg"
    //           />
    //           <h2 className="project-name">Hindustan Times (CP)
    //           </h2>
    //         </div>
    //         <div className="col project-div">
    //           <img
    //             className="inner-project-img"
    //             src="https://th.bing.com/th/id/OIP.jWggH3tyQtck5N7RIrk66AAAAA?rs=1&pid=ImgDetMain"
    //           />
    //           <h2 className="project-name">D A Toll Plaza (gadhpuri)
    //           </h2>
    //         </div>
    //         <div className="col project-div">
    //           <img
    //             className="inner-project-img"
    //             src="https://cdn.dnaindia.com/sites/default/files/styles/full/public/2018/02/21/650686-517884-463269-toll-plaza-representatinal-2.jpg"
    //           />
    //           <h2 className="project-name">D A Toll Plaza (Karmalpur)
    //           </h2>
    //         </div>
    //         <div className="col project-div">
    //           <img
    //             className="inner-project-img"
    //             src="https://content.jdmagicbox.com/comp/delhi/29/011pf021829/catalogue/nath-brothers-connaught-place-delhi-chemists-22daauf.jpg"
    //           />
    //           <h2 className="project-name">Nath Brothers (CP)
    //           </h2>
    //         </div>
            
           
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
};

export default Project;
