import './App.css';
import './Components/mobile.css';
import About from './Components/About';
import Hero from './Components/Hero';
import Navbar from './Components/Navbar';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Services from './Components/Services';
import Project from './Components/Project';
import Contact from './Components/Contact';
import Footer from './Components/Footer';


function App() {
  return (
    <div>
      <BrowserRouter>
      <Navbar />
        <Routes>
          <Route>
          <Route path="/" element={<Hero />}/>
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/project" element={<Project />} />
          <Route path='/contact' element={<Contact />} />
          </Route>
        </Routes>
        <Footer />
      </BrowserRouter>
      
    </div>
  );
}

export default App;
