import React from "react";
import { Link } from "react-router-dom";
import hd from "../Images/hd.jpg";
import baani from "../Images/baani.jpg";
import school from "../Images/South_Delhi_Public_School.jpg";
import croma from "../Images/croma.webp";
import splendor from "../Images/splendor.jpg";
import projectImg1 from "../Images/img1.jpg";
import projectImg2 from "../Images/img2.jpg";
import projectImg3 from "../Images/img3.jpg";
import projectImg4 from "../Images/project-img4.jpg";
import projectImg5 from "../Images/project-img5.jpg";
import projectImg6 from "../Images/project-img6.jpg";
import motimahal from "../Images/motimahal.jpg";
import bobachee from "../Images/bobachee.jpg";
import irispark from "../Images/irispark.jpg";
import printtech from "../Images/printtech.jpg";
import syonmed from "../Images/syon med.jpg";

const Hero = () => {
  return (
    <>
      <div className="hero-img">
        <img
          className="hero-img-img"
          src="https://cdnassets.hw.net/55/07/0d2c353347b5b1d19c340371b5de/construction.jpg"
        />
      </div>
      <div className="hero-text">
        <h1 className="hero-h1">Your Vision, Our Foundation</h1>
        <p className="hero-p">
          At Wafiya, We bring your “Vision” to life with expert tailored
          construction services, innovative solutions, and unwavering commitment
          to quality. Trust us to build excellence from the “Foundation”.
        </p>
        <Link to="/about">
          <button className="btn btn-1">About Us</button>
        </Link>
        <Link to="/contact">
          <button className="btn">Get In Touch</button>
        </Link>
      </div>

      

      <div className="services-container">
      <div className="card" >
        <img className="card-img-top" src="https://scheirman.ph/wp-content/uploads/2021/10/SCCI_Home_Design.jpg" alt="Card image cap" />
        <div className="card-body">
          <h5 className="card-title">Construction management</h5>
          <p className="card-text">
              Effective construction management ensures projects are completed
              on time, within budget, and to the highest standards. With a focus
              on efficiency, quality, and safety, we deliver projects that meet
              your vision and exceed expectations.
          </p>
          <Link href="#" className="btn btn-primary">
            Read More
          </Link>
        </div>
      </div>
      <div className="card">
        <img className="card-img-top" src="https://photoplan3d.com/wp-content/uploads/2020/03/reformas.jpg" alt="Card image cap" />
        <div className="card-body">
          <h5 className="card-title">Construction planning</h5>
          <p className="card-text">
              Construction planning is crucial for a successful project,
              involving meticulous coordination of resources, timelines, and
              budgets. Our expert team creates detailed plans to ensure every phase of
              construction is executed smoothly. With a focus on precision and
              proactive management, we turn your vision into reality.
          </p>
          <Link href="#" className="btn btn-primary">
            Read More
          </Link>
        </div>
      </div>
      <div className="card">
        <img className="card-img-top" src="https://101homerenovation.com/wp-content/uploads/2020/07/banner-new1-1338x873.jpg" alt="Card image cap" />
        <div className="card-body">
          <h5 className="card-title">Building maintenance</h5>
          <p className="card-text">
              Our team provides comprehensive services to address routine
              maintenance, emergency repairs, and preventative measures, helping
              you preserve the value and functionality of your building while
              minimizing disruptions and extending its lifespan
          </p>
          <Link href="#" className="btn btn-primary">
            Read More
          </Link>
        </div>
      </div>
        {/* <div className="each-service">
          <div className="services-img">
            <img
              src="https://scheirman.ph/wp-content/uploads/2021/10/SCCI_Home_Design.jpg"
              className="service-img"
              alt="..."
            />
          </div>
          <div className="services-content">
            <h4 className="services-title">Construction management</h4>
            <p className="ser-text">
              Effective construction management ensures projects are completed
              on time, within budget, and to the highest standards. With a focus
              on efficiency, quality, and safety, we deliver projects that meet
              your vision and exceed expectations.
            </p>
            <button className="btn service-btn">
              <Link className="link">Read More</Link>
            </button>
          </div>
        </div>
        <div className="each-service">
          <div className="services-img">
            <img
              src="https://photoplan3d.com/wp-content/uploads/2020/03/reformas.jpg"
              className="service-img"
              alt="..."
            />
          </div>
          <div className="services-content">
            <h4 className="services-title">Construction planning</h4>
            <p className="ser-text">
              Construction planning is crucial for a successful project,
              involving meticulous coordination of resources, timelines, and
              budgets. Our expert team creates detailed plans to anticipate
              challenges, streamline processes, and ensure every phase of
              construction is executed smoothly. With a focus on precision and
              proactive management, we turn your vision into a
              well-organized reality.
            </p>
            <button className="btn service-btn">
              <Link className="link">Read More</Link>
            </button>
          </div>
        </div>
        <div className="each-service">
          <div className="services-img">
            <img
              src="https://101homerenovation.com/wp-content/uploads/2020/07/banner-new1-1338x873.jpg"
              className="service-img"
              alt="..."
            />
          </div>
          <div className="services-content">
            <h4 className="services-title">Building maintenance</h4>
            <p className="ser-text">
              Our team provides comprehensive services to address routine
              maintenance, emergency repairs, and preventative measures, helping
              you preserve the value and functionality of your building while
              minimizing disruptions and extending its lifespan
            </p>
            <button className="btn service-btn">
              <Link className="link">Read More</Link>
            </button>
          </div>
        </div>
      </div> */}
      </div>

      <div>
        <div className="choose-container">
          <div className="choose-image rounded float-left">
            <img src="https://www.architectandinteriorsindia.com/public/images/2020/07/13/shutterstock_627164000.jpg" />
          </div>
          <div className="choose-text rounded float-right">
            <h1>
              The reasons why you should choose{" "}
              <span style={{ color: "white" }}>Wafiya</span>
            </h1>
            <hr />
            <p>
              Choose Wafiya Constructions Pvt Ltd for expertise, reliability,
              and excellence. With a proven track record of innovative
              solutions, exceptional quality, on-time completion, and
              personalized service, we ensure your vision becomes reality.
            </p>
            <ul>
              <li>
                <span>✔️</span>Proven track record of delivering innovative
                solutions.
              </li>
              <li>
                <span>✔️</span>Consistently high-quality outcomes.
              </li>
              <li>
                <span>✔️</span>On-time project completion.
              </li>
              <li>
                <span>✔️</span>Tailored solutions to meet client needs.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="projects">
        <h2>recent projects</h2>
        <hr />
        <div className="project-container">
          <div className="project-img">
            <img src={projectImg1} />
          </div>
          <div className="project-img">
            <img src={projectImg2} />
          </div>
          <div className="project-img">
            <img src={projectImg3} />
          </div>
          <div className="project-img">
            <img src={projectImg4} />
          </div>
          <div className="project-img">
            <img src={projectImg5} />
          </div>
          <div className="project-img">
            <img src={projectImg6} />
          </div>
          <div className="project-img">
            <img src={projectImg1} />
          </div>
          <div className="project-img">
            <img src={projectImg1} />
          </div>
          <div className="project-img">
            <img src={projectImg2} />
          </div>
          <div className="project-img">
            <img src={projectImg3} />
          </div>
          <div className="project-img">
            <img src={projectImg4} />
          </div>
          <div className="project-img">
            <img src={projectImg5} />
          </div>
          <div className="project-img">
            <img src={projectImg6} />
          </div>
        </div>
        <div className="readMore">
          <Link className="project-link" to="/project">
            View All
          </Link>
        </div>
      </div>

      <div className="client-container">
        <h2>our clients</h2>
        <hr />
        <div className="clients">
          <img className="client-img" src={hd} />
          <img className="client-img" src={croma} />
          <img className="client-img" src={splendor} />
          <img className="client-img" src={school} />
          <img className="client-img" src={baani} />
          <img className="client-img" src={motimahal} />
          <img className="client-img" src={syonmed} />
          <img className="client-img" src={bobachee} />
          <img className="client-img" src={printtech} />
          <img className="client-img" src={irispark} />
          <img className="client-img" src={hd} />
          <img className="client-img" src={croma} />
          <img className="client-img" src={splendor} />
          <img className="client-img" src={school} />
          <img className="client-img" src={baani} />
          <img className="client-img" src={motimahal} />
          <img className="client-img" src={syonmed} />
          <img className="client-img" src={bobachee} />
          <img className="client-img" src={printtech} />
          <img className="client-img" src={irispark} />
        </div>
      </div>
    </>
  );
};

export default Hero;
