import React from "react";
import './contact.css';

const Contact = () => {
  return (
    <>
      <div className="contact-container">
        <h1 className="contact-h1">Wafiya Construction Pvt. Ltd.</h1>
        <h5 className="contact-p">Feel free to contact us!</h5>
        <form>
        <div className="form-group">
            <label for="inputAddress" className="contact-text">Name</label>
            <input
              type="text"
              className="form-control"
              id="inputAddress"
              placeholder="Your Name"
            />
          </div>
          <div className="form-group">
            <label for="exampleInputEmail1" className="contact-text">Email address</label>
            <input
              type="email"
              className="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              placeholder="Enter email"
            />
          </div>

          <div className="form-group">
            <label for="exampleFormControlTextarea1" className="contact-text">Your Queries</label>
            <textarea
              className="form-control"
              id="exampleFormControlTextarea1"
              rows="3"
            ></textarea>
          </div>
          <button type="submit" className="btn btn-contact">
            Send
          </button>
        </form>
      </div>
    </>
  );
};

export default Contact;
