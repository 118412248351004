import React from 'react'
import logo from "../Images/wafiya-logo.jpg"

const Footer = () => {
  return (
    <div>
        <footer className="footer">
        <div className="footer-content">
          <div className="brand">
            <p><img className="logo" src={logo} />Wafiya Constructions Pvt Ltd</p>
          </div>
          <div className="social-footer">
            <img
              className="footer-icon"
              width="30"
              height="30"
              src="https://img.icons8.com/color/48/linkedin.png"
              alt="linkedin"
            />
            <img
              className="footer-icon"
              width="30"
              height="30"
              src="https://img.icons8.com/fluency/48/instagram-new.png"
              alt="instagram-new"
            />
            <img
              className="footer-icon"
              width="30"
              height="30"
              src="https://img.icons8.com/color/48/facebook-new.png"
              alt="facebook-new"
            />
            <img
              className="footer-icon"
              width="30"
              height="30"
              src="https://img.icons8.com/color/48/twitter--v1.png"
              alt="twitter--v1"
            />
          </div>
        </div>
        <div className="copyright">
          <p>Copyright ©WafiyaConstructions. All Rights Reserved.</p>
        </div>
      </footer>
    </div>
  )
}

export default Footer