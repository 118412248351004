import React from "react";
import "./services.css";

const Services = () => {
  return (
    <div>
      {/* <div className="services-hero-img">
       <img
          
          // src="https://www.in2space.com.au/wp-content/uploads/2020/10/Modern-open-office-design-5.jpg"
        />
      </div> */}
      <div className="services-text">
        <h3 className="services-h3">Scope of Services</h3>
        <hr className="services-hr"/>
        <div className='eachServiceBlock'>
          <p><i class="fa-solid fa-house services-icon"></i></p>
          <h6 className="services-h4">Residential Construction:</h6>
          <p className='services-desc'>
            From custom homes to multi-family residences, we specialize in
            designing and building residential properties that reflect our
            clients' unique lifestyles and preferences. Our team handles
            everything from initial design concepts to final construction,
            ensuring a seamless and enjoyable building experience.
          </p>
        </div>
        <div className='eachServiceBlock'>
         <p><i class="fa-solid fa-building services-icon"></i></p>
          <h6 className="services-h4">Commercial Construction:</h6>
          <p className='services-desc'>
            We excel in constructing commercial spaces that inspire productivity
            and reflect our clients' corporate identities. Whether it's office
            complexes, retail spaces, or hospitality venues, we deliver
            functional and aesthetically pleasing environments that meet the
            demands of modern businesses.
          </p>
        </div>
        <div className='eachServiceBlock'>
          <p><i class="fa-solid fa-industry services-icon"></i></p>
          <h6 className="services-h4">Industrial Construction:</h6>
          <p className='services-desc'>
            With expertise in industrial projects, we undertake the construction
            of manufacturing facilities, warehouses, and industrial complexes.
            Our focus on efficiency and safety ensures that these critical
            spaces are built to optimize operational workflows and withstand
            rigorous demands.
          </p>
        </div>
        <div className='eachServiceBlock'>
        <p><i class="fa-solid fa-store services-icon"></i></p>
          <h6 className="services-h4">Renovation and Remodeling:</h6>
          <p className='services-desc'>
            Enhance the value and functionality of your existing property with
            our renovation and remodeling services. Whether it's a complete
            interior overhaul or exterior upgrades, we breathe new life into
            spaces while respecting the integrity of the original structure.
          </p>
        </div>
        <div className='eachServiceBlock'>
        <p><i class="fa-solid fa-list-check services-icon"></i></p>
          <h6 className="services-h4">Project Management:</h6>
          <p className='services-desc'>
            Our project management services streamline the construction process
            from start to finish. We oversee scheduling, budgeting, quality
            control, and logistics to ensure projects are completed on time,
            within budget, and to the highest standards of craftsmanship.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Services;
