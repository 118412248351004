import React from "react";
import "./about.css";

const About = () => {
  return (
    <div>
      {/* <div className="about-img">
        <img className="about-img-img" src="https://www.decorilla.com/online-decorating/wp-content/uploads/2022/10/Wellesley-interior-design-Nedith-Wikina.jpg" />
      </div> */}
      <div className="about-img">
      <h3 className="about-h3">
            Wafiya Constructions Pvt Ltd stands as a beacon of expertise and
            reliability in the construction industry. With a steadfast
            commitment to excellence, we specialize in delivering innovative
            solutions across residential, commercial, and industrial projects.
          </h3>
      </div>
      <div className="about-div">
        <div className="about-div-inside">
          <h1>20+</h1>
          <p>Years of Rich Experience</p>
        </div>
        <div className="about-div-inside">
          <h1>50+</h1>
          Prestigious Clients
        </div>
        {/* <div className='about-div-inside'></div>
            <div className='about-div-inside'></div> */}
      </div>
      <div className="about">
        <div className="about-text">

          <p className="about-p">
            At Wafiya Constructions, we pride ourselves on transforming
            ambitious visions into tangible realities. Founded on principles of
            integrity and professionalism, our team leverages years of
            experience to navigate complexities and deliver superior
            construction outcomes. Our dedication to quality craftsmanship and
            on-time project completion underscores every endeavor, ensuring
            seamless execution and client satisfaction. We prioritize
            personalized service, tailoring each project to meet unique client
            needs while adhering to rigorous standards of sustainability and
            efficiency.
          </p>
          <p className="about-p">
            Driven by a commitment to building a better future, Wafiya
            Constructions forges lasting partnerships based on trust,
            transparency, and shared goals. Whether shaping skylines or
            enhancing communities, we are dedicated to leaving a lasting legacy
            of quality and reliability in every project we undertake.
          </p>
        </div>
        <div class="container text-center">
          <div class="row row-cols-2">
            <div class="col">
              <img
                className="about-text-img"
                src="https://th.bing.com/th/id/OIP.5U0M_e0cQyOQNCA4XbyZ0QAAAA?rs=1&pid=ImgDetMain"
              />
            </div>
            <div class="col">
              <img
                className="about-text-img"
                src="https://th.bing.com/th/id/OIP.bZztB67hPbtVotQXB6vIZAAAAA?rs=1&pid=ImgDetMain"
              />
            </div>
            <div class="col">
              <img
                className="about-text-img"
                src="https://th.bing.com/th/id/OIP.FadSgHNPzw5nyiiVlnV8FwAAAA?rs=1&pid=ImgDetMain"
              />
            </div>
            <div class="col">
              <img
                className="about-text-img"
                src="https://th.bing.com/th/id/OIP.3rBbmM30_1x-yPgDQMoPpAAAAA?rs=1&pid=ImgDetMain"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="accordion accordion-flush" id="accordionFlushExample">
        <div class="accordion-item">
          <h2 class="accordion-header">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseOne"
              aria-expanded="false"
              aria-controls="flush-collapseOne"
            >
              OUR VALUES
            </button>
          </h2>
          <div
            id="flush-collapseOne"
            class="accordion-collapse collapse"
            data-bs-parent="#accordionFlushExample"
          >
            <div class="accordion-body">
              <ul>
                <li>
                  <h6>Integrity:</h6> We conduct business with the highest
                  ethical standards, ensuring transparency, honesty, and
                  fairness in all our dealings. Our clients trust us because we
                  uphold our commitments and deliver on our promises.
                </li>
                <li>
                  <h6>Excellence:</h6> We strive for excellence in everything we
                  do, from the initial planning stages to the final construction
                  phase. Our dedication to quality craftsmanship, meticulous
                  attention to detail, and adherence to industry best practices
                  ensure that we consistently deliver superior outcomes.
                </li>
                <li>
                  <h6>Innovation:</h6> We embrace innovation as a driving force
                  behind our success. Continuously seeking new technologies,
                  materials, and construction methods allows us to enhance
                  efficiency, minimize environmental impact, and deliver
                  innovative solutions that exceed our clients' expectations.
                </li>
                <li>
                  <h6>Client-Centric Approach:</h6> Our clients are at the heart
                  of everything we do. We take the time to understand their
                  unique needs and preferences, tailoring our approach to
                  deliver personalized solutions that meet and exceed their
                  expectations. Building strong relationships based on trust and
                  mutual respect is fundamental to our success.
                </li>
                <li>
                  <h6>Sustainability:</h6> We are committed to sustainability
                  and environmental stewardship. Integrating sustainable
                  practices into our projects not only minimizes our ecological
                  footprint but also enhances the long-term value and durability
                  of the structures we build.
                </li>
                <li>
                  <h6>Teamwork:</h6> Collaboration is key to our success. We
                  foster a culture of teamwork and mutual support among our
                  diverse team of professionals. By leveraging each individual's
                  strengths and expertise, we achieve exceptional results and
                  foster a positive work environment.
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseTwo"
              aria-expanded="false"
              aria-controls="flush-collapseTwo"
            >
              WHY CHOOSE US
            </button>
          </h2>
          <div
            id="flush-collapseTwo"
            class="accordion-collapse collapse"
            data-bs-parent="#accordionFlushExample"
          >
            <div class="accordion-body">
              Choosing Wafiya Constructions Pvt Ltd means entrusting your
              construction project to a team with proven expertise and a solid
              track record of successful projects. We are committed to
              delivering exceptional quality through meticulous attention to
              detail and superior craftsmanship at every stage of the
              construction process. Our approach is characterized by innovative
              solutions that leverage cutting-edge technologies and construction
              methods, tailored to meet the specific needs of our clients. We
              prioritize a client-centric approach, ensuring personalized
              service and dedicated support from initial planning to project
              completion. Moreover, our commitment to sustainability drives us
              to integrate environmentally responsible practices into all our
              projects, aiming to create enduring and efficient structures. With
              Wafiya Constructions, you can rest assured that your project will
              be managed with professionalism, innovation, and a steadfast focus
              on exceeding your expectations.
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseThree"
              aria-expanded="false"
              aria-controls="flush-collapseThree"
            >
              OUR APPROACH
            </button>
          </h2>
          <div
            id="flush-collapseThree"
            class="accordion-collapse collapse"
            data-bs-parent="#accordionFlushExample"
          >
            <div class="accordion-body">
              At Wafiya Constructions Pvt Ltd, our approach to construction is
              founded on a blend of expertise, innovation, and client-centered
              service. We begin by thoroughly understanding our clients' vision
              and requirements, ensuring that each project is tailored to their
              specific needs. Our team leverages years of experience and
              industry knowledge to implement efficient project management
              practices that prioritize transparency, accountability, and
              proactive communication. Throughout the construction process, we
              maintain a relentless focus on quality, adhering to rigorous
              standards and integrating cutting-edge technologies to optimize
              efficiency and minimize environmental impact. We believe in
              fostering collaborative partnerships with clients, consultants,
              and stakeholders to deliver projects on time, within budget, and
              to the highest standards of excellence. At Wafiya Constructions,
              our approach is not just about building structures; it's about
              building lasting relationships and exceeding expectations in every
              aspect of construction.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
