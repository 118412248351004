import React from "react";
import { Link } from "react-router-dom";
import Logo from "../Images/wafiya-logo.jpg"

const Navbar = () => {
  return (
    <>
      <nav className="navbar navbar-expand-lg">
        <div className="container-fluid">
          <img className="logo" src={Logo} />
          <a className="navbar-brand" href="#">
            WAFIYA CONSTRUCTIONS
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <Link className="nav-link" aria-current="page" to="/">
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/about">
                  About
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/services" className="nav-link" aria-disabled="true">
                  Services
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/project" className="nav-link" aria-disabled="true">
                  Projects
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/contact" className="nav-link" aria-disabled="true">
                  Contact Us
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
